import { validatorPhoneEQI } from '@/services/http/apiValidatorEQI';
import { sendEventToWebhookPP } from '@/services/http/webhookPP';
import Cookies from 'js-cookie';
import { inputGroups } from '@/config/form/inputGroups';
import { statesDDD } from '@/config/form/states';
import { getDecryptedCookie } from '@/common/cookies';
import { validateEmail, validatePhone } from '@/common/validators';
import { Lead } from '../Lead';
import { ProgressiveProfile } from '../ProgressiveProfile';
import { renderInput } from './Inputs';
import { mountSpanError } from './utils/mountSpanError';
export class Form {
    formId;
    styles;
    constructor(formId = 'form-progressive-profile', styles) {
        this.formId = formId;
        this.styles = styles;
    }
    getFormElement() {
        return window.document.querySelectorAll(`[id^=${this.formId}]`);
    }
    /** Method that render the form, according to the formId
     * @param {string} formId
     */
    renderForm(formId) {
        const groupToShow = inputGroups[formId || 'default'];
        const forms = this.getFormElement();
        this.injectStyleSelectJob();
        forms.forEach((form) => {
            const FormProgressiveProfile = document.createElement('div');
            this.queryParamsFields(FormProgressiveProfile);
            this.getFbCookies(FormProgressiveProfile);
            form.insertAdjacentElement('afterbegin', FormProgressiveProfile);
            groupToShow.inputs.forEach((input) => {
                const divInput = renderInput(input, this.styles);
                FormProgressiveProfile.appendChild(divInput);
                if (input.name === 'Email' || input.name === 'MobilePhone')
                    this.validateFields(input, divInput);
                if (input.name === 'Job__c') {
                    this.replaceSelectJobWithDiv(input);
                }
            });
        });
    }
    /**
     * Check if there's query params and adds then as a hidden input on the form
     * @param form
     */
    queryParamsFields(form) {
        const currentUrlParams = window.location.search;
        if (currentUrlParams) {
            const queryParams = currentUrlParams
                .split('?')[1]
                .split('&')
                .map((queryParam) => queryParam.split('='));
            queryParams.map(([key, value]) => {
                this.createHiddenInput(form, key, value);
            });
        }
    }
    /**
     * Insert style for the Job__C select form
     */
    injectStyleSelectJob() {
        const style = document.createElement('style');
        style.textContent = `
      #searchOptions {
        display: none;
        position: absolute;
        z-index: 2;
        width: 300px;
        max-height: 200px;
        overflow-y: auto;
        top:35px;
        background-color: #fff;
        border: 1px solid #ccc;
        border-radius: 4px;
        padding: 8px;
        cursor: pointer;
        color:rgb(156, 156, 173);
      }
      #searchOptions > div {
        padding: 5px 0;
        cursor: pointer;
        z-index: 3;
        background-color:#fff;
	      color:rgb(156, 156, 173);
        font-size: 0.8em;
      }
      input.searchInput {
        width: 100%;
        padding: 8px;
        box-sizing: border-box;
      }
    `;
        document.head.appendChild(style);
    }
    /**
     * Check if there's facebook cookies and adds then as a hidden input on the form
     * @param form
     */
    getFbCookies(form) {
        const fbCookies = {
            _fbc: 'FBCLID__c',
            _fbp: 'FBPLID__c',
        };
        Object.entries(fbCookies).map(([cookieName, inputName]) => {
            const cookieValue = Cookies.get(cookieName);
            if (cookieValue) {
                this.createHiddenInput(form, inputName, cookieValue);
            }
        });
    }
    /**
     * Create the hidden input on the form
     * @param form
     * @param name
     * @param value
     */
    createHiddenInput(form, name, value) {
        const divHiddenInput = renderInput({
            id: name,
            label: '',
            name: name,
            type: 'hidden',
            value: value,
        });
        form.appendChild(divHiddenInput);
    }
    /**
     *  Replace select input for div to manipulate the Job__C field
     * @param input
     */
    replaceSelectJobWithDiv(input) {
        const selectsJob = document.querySelectorAll(`select[name="form_fields[${input.name}]"]`);
        const searchInput = document.createElement('input');
        searchInput.type = 'text';
        searchInput.id = 'form-field-Job__c';
        searchInput.name = 'form_fields[Job__c]';
        searchInput.classList.add('searchInput');
        searchInput.placeholder = 'Digite uma profissão...';
        selectsJob.forEach((select) => {
            const divJobs = document.createElement('div');
            divJobs.id = 'searchOptions';
            divJobs.className = select.className;
            Array.from(select.options).forEach((option) => {
                const optionDiv = document.createElement('div');
                optionDiv.textContent = option.textContent;
                optionDiv.ariaLabel = option.value;
                optionDiv.addEventListener('click', function () {
                    searchInput.value = optionDiv.textContent || '';
                    select.value = optionDiv.ariaLabel || '';
                });
                divJobs.appendChild(optionDiv);
            });
            select.parentNode?.insertBefore(searchInput, select);
            select.parentNode?.replaceChild(divJobs, select);
            this.handleJobSelect(divJobs);
        });
    }
    handleJobSelect(divJobs) {
        const searchInputs = document.querySelectorAll('.searchInput');
        searchInputs.forEach((inputSearch) => {
            const selectBox = inputSearch.nextElementSibling;
            let isInputVisible = false;
            // Open/close select options
            inputSearch.addEventListener('click', () => {
                isInputVisible = !isInputVisible;
                selectBox.style.display = isInputVisible ? 'block' : 'none';
            });
            // Close select when user click outside the fields
            document.addEventListener('click', (event) => {
                const target = event.target;
                if (!target.closest('.elementor-field') &&
                    !target.closest('#searchOptions')) {
                    selectBox.style.display = 'none';
                }
            });
            // Filter options as the user types
            inputSearch.addEventListener('input', () => {
                const filter = inputSearch.value.toLowerCase();
                divJobs
                    .querySelectorAll('#searchOptions > div')
                    .forEach((optionDiv) => {
                    optionDiv.style.display = optionDiv.textContent
                        ?.toLowerCase()
                        .includes(filter)
                        ? ''
                        : 'none';
                });
            });
            // Deals with options selection
            selectBox.addEventListener('click', (event) => {
                const target = event.target;
                if (target) {
                    inputSearch.value = target.textContent || '';
                    selectBox.style.display = 'none';
                }
            });
        });
    }
    /** Email and Phone validations accordding to the events */
    validateFields(input, divInput) {
        const inputName = document.querySelectorAll(`#form-field-${input.name}`);
        inputName.forEach((input) => {
            if (input.name === 'form_fields[MobilePhone]')
                input.addEventListener('keyup', this.formatPhone);
            input.addEventListener('input', (input) => {
                const elementTarget = input.target;
                if (elementTarget.type === 'email')
                    this.handleEmailValidation(elementTarget, divInput);
                if (elementTarget.name === 'form_fields[MobilePhone]')
                    this.handlePhoneValidations(elementTarget, divInput);
            });
        });
    }
    /** Check if email is valid */
    handleEmailValidation(input, divInput) {
        const checked = validateEmail(input.value);
        if (!checked) {
            mountSpanError(divInput);
            this.submitStatus(true);
        }
        else {
            this.removeErrorInput(divInput);
            this.submitStatus(false);
        }
    }
    /** Insert the mask (XX) XXXX-XXXX into the Phone */
    formatPhone(event) {
        const input = event.target;
        let phoneValue = input.value;
        phoneValue = phoneValue.replace(/[^0-9]/g, '');
        const area = phoneValue.substr(0, 2);
        const pre = phoneValue.substr(2, 5);
        const tel = phoneValue.substr(7, 4);
        let formattedPhone = '';
        if (area.length < 2) {
            formattedPhone = '(' + area;
        }
        else if (area.length === 2 && pre.length < 5) {
            formattedPhone = '(' + area + ')' + ' ' + pre;
        }
        else if (area.length === 2 && pre.length === 5) {
            formattedPhone = '(' + area + ')' + ' ' + pre + '-' + tel;
        }
        input.value = formattedPhone;
    }
    /** Check all the phone validations */
    async handlePhoneValidations(element, divInput) {
        const unformattedPhone = element.value.replace(/\D/g, '');
        const isValid = validatePhone(unformattedPhone);
        const validaEQI = await validatorPhoneEQI(unformattedPhone);
        if (!isValid || !validaEQI) {
            mountSpanError(divInput, this.styles);
            this.submitStatus(true);
        }
        else {
            this.removeErrorInput(divInput);
            this.submitStatus(false);
        }
        this.setStateByDDD(unformattedPhone);
    }
    /** Remove the error message from the screen */
    removeErrorInput(divInput) {
        const errorSpan = divInput.querySelectorAll('.message-danger');
        errorSpan.forEach((element) => {
            element.remove();
        });
    }
    /** Disable or Able click on submit button */
    submitStatus(status) {
        const forms = this.getFormElement();
        forms.forEach((form) => {
            const btSubmit = form.querySelector('[type="submit"]');
            if (btSubmit instanceof HTMLButtonElement ||
                btSubmit instanceof HTMLInputElement) {
                btSubmit.disabled = status;
            }
        });
    }
    /** Set the user state based on his phone DDD */
    setStateByDDD(phone) {
        const ddd = phone.substring(0, 2);
        const addressInputs = document.querySelectorAll('#form-field-State');
        const estado = statesDDD[ddd];
        addressInputs.forEach((input) => {
            input.value = estado;
        });
    }
    /** If the user has previously filled out a form, a cookie is saved with data (name, email and phone) and now is automatically fill
  
     * @param leadData
     */
    renderAutofillInputs(leadData) {
        const nameFields = document.querySelectorAll('[id^=form-field-Name]');
        const emailFields = document.querySelectorAll('[id^=form-field-Email]');
        const mobilePhoneFields = document.querySelectorAll('[id^=form-field-MobilePhone]');
        nameFields.forEach((field) => {
            field.value = leadData.name;
        });
        emailFields.forEach((field) => {
            field.value = leadData.email;
        });
        mobilePhoneFields.forEach((field) => {
            field.value = leadData.phone;
        });
    }
    /** Takes the data filled in the form and organizes into an object */
    listenerSubmitForm() {
        const inputsToSubmit = {};
        const forms = this.getFormElement();
        forms.forEach((form) => {
            form.addEventListener('submit', async (e) => {
                e.preventDefault();
                const formData = new FormData(e.target);
                const formObject = Object.fromEntries(formData.entries());
                Object.entries(formObject).forEach((inputValue) => {
                    const inputName = inputValue[0]
                        .replace('form_fields[', '')
                        .replace(']', '');
                    if (typeof inputValue[1] === 'string') {
                        inputsToSubmit[inputName] = inputValue[1];
                    }
                    /** Create a cookie with liquidity to send on all submitted forms
                     */
                    if (inputName === 'RDValueRangeReported__c') {
                        Lead.createCookiePersistFields({
                            fieldName: 'liquidez',
                            value: inputValue[1],
                        });
                    }
                });
                this.submitLead(inputsToSubmit);
            });
        });
    }
    /** Send form data to webhook and change the cookie ID, if everything it's OK */
    async submitLead(inputsToSubmit) {
        /** Check if liquidity cookie exists, if it exists, send it with the rest of the fields */
        const persistFields = Lead.getPersistFieldFromCookie();
        if (persistFields) {
            inputsToSubmit['RDValueRangeReported__c'] = persistFields?.value;
        }
        await sendEventToWebhookPP(inputsToSubmit);
        const newPP = new ProgressiveProfile();
        const sendValueCookie = getDecryptedCookie(newPP.ppCookieName);
        newPP.changeFormId(JSON.parse(sendValueCookie));
    }
}
