export const mountSpanError = (divInput, styles) => {
    const hasSpan = divInput.querySelector('.message-danger');
    const messageMobilePhone = divInput.querySelector('#form-field-MobilePhone');
    if (!divInput.contains(hasSpan)) {
        const spanElement = document.createElement('span');
        const textGeneral = 'Este campo é obrigatório.';
        const textMobilePhone = 'Insira um telefone válido.';
        const text = messageMobilePhone
            ? document.createTextNode(textMobilePhone)
            : document.createTextNode(textGeneral);
        spanElement.className = styles?.error ? styles.error : 'message-danger';
        spanElement.role = 'alert';
        spanElement.style.color = 'red';
        spanElement.style.fontSize = '12px';
        spanElement.appendChild(text);
        divInput.appendChild(spanElement);
        return spanElement;
    }
};
