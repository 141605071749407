import { LEAD_COOKIE_KEYS } from '@/interfaces/Lead/enum';
import { getJSONCookie, setEncryptedCookie } from '@/common/cookies';
import { hash } from '@/common/encryption';
import { validateEmail, validatePhone } from '@/common/validators';
/**
 * Responsável pelo gerenciamento dos dados do lead
 */
export class Lead {
    #data = null;
    constructor(lead) {
        this.identify(lead);
    }
    /**
     * Gera um id único para o lead, a partir de seus dados de conversão (nome, e-mail e telefone)
     * @param leadData - Os dados de conversão do lead
     */
    async identify(leadData) {
        try {
            const lead = this.#encryptAndSave(leadData);
            this.#data = lead;
        }
        catch (error) {
            console.error(error);
        }
    }
    /**
     * Valida os dados do lead, gera um id único e salva os valores encriptados no cookie
     * @param data - Os dados de conversão do lead
     */
    #encryptAndSave(data) {
        const isValid = this.#validate(data);
        if (!isValid) {
            throw new Error('Lead data is invalid!');
        }
        const id = this.#generateLeadId(data);
        const lead = { ...data, id };
        setEncryptedCookie({
            key: LEAD_COOKIE_KEYS.EqiTracking,
            value: JSON.stringify(lead),
        });
        return lead;
    }
    static createCookiePersistFields(fields) {
        setEncryptedCookie({
            key: LEAD_COOKIE_KEYS.EqiPersistFields,
            value: JSON.stringify(fields),
        });
    }
    /**
     * Valida os dados do lead e retorna `true`, em caso de sucesso, ou um erro em caso de dados inválidos
     * @param data - Os dados de conversão do lead
     */
    #validate(data) {
        if (!data)
            throw new Error('No data for sdk!');
        const isEmailValid = validateEmail(data.email);
        const isPhoneValid = validatePhone(data.phone);
        return isEmailValid && isPhoneValid;
    }
    /**
     * Gera um identificador único a partir do email e telefone do lead
     * @param email - O email informado pelo lead
     * @param phone - O telefone informado pelo lead
     */
    #generateLeadId({ email, phone }) {
        return hash(JSON.stringify({ email, phone }));
    }
    /**
     * Retorna os dados setados no cookie, sem fazer uma validação
     */
    static getLeadFromCookie() {
        return getJSONCookie(LEAD_COOKIE_KEYS.EqiTracking);
    }
    static getPersistFieldFromCookie() {
        return getJSONCookie(LEAD_COOKIE_KEYS.EqiPersistFields);
    }
    /**
     * Retorna um booleano indicando se o lead já foi identificado
     */
    get isIdentified() {
        return this.#data !== null;
    }
    /**
     * Retorna os dados validados do lead, caso existam, ou nulo
     */
    get leadDataIfValid() {
        return this.#data;
    }
}
